@import '../variables.scss';
@import '../styles.scss';

.disclaimerBtn {
  color: $primary-color;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-family: 'Montserrat';
  font-size: 14px;
  margin-top: 10px;

  &:hover {
    color: #53dbbb;
  }
}

.content {
  background-color: #f5f5f5;
  padding: 25px;
  border-radius: 10px;
  width: 450px;
  max-width: 80vw;
  border:1px solid #ddd;
  transition: all .3s ease;
  animation: fadein .3s;
  .link {
    color: #21b8a2;
    text-decoration: none;
    cursor: pointer;
    
    &:hover {
      color: #53dbbb;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    scale: .4;
  } to {
    opacity: 1;
    scale: 1;;
  }
}

