@import '../variables.scss';
.title {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  @media screen and (max-width:600px){
    font-size: 18px;
  }

  .legendCosts {
    background-color: $secondary-color;
    padding: 5px 10px;
    margin: 0 5px;
    color: white;
    border-radius: 8px;
    @media screen and (max-width: 600px){
      font-size: 18px;
      padding: 2px 5px;
    }
  }

  .legendSavings {
    background-color: $primary-color;
    padding: 5px 10px;
    margin: 0 5px;
    color: white;
    border-radius: 8px;
    @media screen and (max-width: 600px){
      font-size: 18px;
      padding: 2px 5px;
    }
  }
}

.chartWrapper {
  height: 30vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.currentFees {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  width: 40%;
  height: 100%;
  max-width: 300px;
  background: #221c34;
  margin: 0 15px;
  border-radius: 10px;
  padding: 15px
}

.blFeeWrapper {
  height: 100%; 
  width: 40%; 
  max-width: 300px; 
  background-color: #f2f2f2;
  margin: 0 15px;
  border-radius:10px;

  .profit {
    color: white;
    width: 100%;
    background-color: #21b8a2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    &.slim {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .blFees {
    color: white;
    width: 100%;
    background-color: #221c34;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 10px 10px;
    
  }
}

.barInfo {
  margin: 0 auto 5px;
  text-align: center;
}

.chartLabels {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .label {
    width: 50%;
    font-size: 24px;
    text-align: center;
    @media screen and (max-width:600px) {
      font-size: 18px;
    }
    &:nth-child(2){
      font-size: 32px;
      @media screen and (max-width:600px) {
        font-size: 24px;
      }
    }
    .less {
      font-weight: bold;
      color: $primary-color;
    }
  }
}
