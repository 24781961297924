@import './variables.scss';

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat-light-300.woff2') format('woff2');
  font-weight: lighter;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat-v14-latin-regular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat-bold-600.woff2') format('woff2');
  font-weight: bold; 
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-Regular.woff2') format('woff2');
  font-weight: normal; 
}

@font-face {
  font-family: 'Panton';
  src: url('./fonts/Panton-Bold.woff2') format('woff2');
  font-weight: bold; 
}

body {
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  width: 100vw;
  * {
    box-sizing: border-box;
    font-family: 'Montserrat';
    font-weight: lighter;
  }

  h1, h2, h3 {
    font-family: "Panton";
  }
  padding: 50px 0;
}

.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-flow: column;
}

.AppHeader {
  text-align: center;
  width: 100vw;
  .title{
    color: $primary-color;
  }
}

.logo {
  width: 300px;
}