@import '../../variables.scss';

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.inputWrapper {
  padding: 20px;
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  .inputLabel {
    margin-bottom: 15px;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  * {
    width:50%;
    @media screen and (max-width: 768px){
      width: 100%;
      text-align: center;
    }
  }

  .rangeValue {
    margin-top: 10px;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: $primary-color;
    border-radius: 50%;
    cursor: pointer;
    border: none;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: $primary-color;
    border-radius: 50%;
    cursor: pointer;
    border: none;
  }

  .clientType {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .client {
       -webkit-appearance: none;
       -moz-appearance: none;
       appearance: none;
       background-color: #f1f1f1;
       display: inline-block;
       position: relative;
       color: #666;
       top: 10px;
       height: 30px;
       width: 30px;
       border: 0;
       border-radius: 50px;
       cursor: pointer;     
       margin-right: 7px;
       outline: none;
       transition: all .5s ease;
  }
  .client:hover {
       background-color: #53dbbb;
  }
  .client:checked {
       background-color: #21b8a2;
  }
}
