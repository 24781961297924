.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin: 0auto;
    .button {
      text-transform: uppercase;
      background: linear-gradient(90deg, #21b8a2, #53dbbb);
      color: white;
      cursor: pointer;
      border: none;
      border-radius: 8px;
      padding: 1rem 2rem;
      font-size: 16px;
      transition: all .3s ease;
    
      &:hover {
        transform: scale(1.1);
        box-shadow: 0px 2px 12px #00000040;
      }
    }
  }
}